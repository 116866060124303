import React, { useState, lazy } from 'react';
import { useParams } from 'react-router-dom';
import AuthProvider from '../../contexts/authContext.js';
import HeaderBar from '../header-bar/component.js';

const QuizWizard = lazy(() => import('../pages/quiz-wizard/component.js'));
const ExplorePageMobile = lazy(() => import('../pages/explore/mobile/component.js'));
const AboutPageMobile = lazy(() => import('../pages/about/mobile/component.js'));
const AboutPage = lazy(() => import('../pages/about/component.js'));
const ExplorePage = lazy(() => import('../pages/explore/component.js'));
const Results = lazy(() => import('../pages/results/component.js'));
const Wishlists = lazy(() => import('../pages/wishlists/component.js'));
const MentionsPage = lazy(() => import('containers/pages/mentions/component.js'));

const Wizard = ({
  goToSectionIndex,
  hidePriceFilter,
  hideNSFWToggle,
  imageSrcOne,
  imageSrcTwo,
  metaDescription,
  pageType,
  showNsfw,
  title,
}) => {

  const [resetQuiz, setResetQuiz] = useState(false);
  const { wishlistId } = useParams()
  const isMobile = localStorage.getItem('isMobile') === 'true';

  let CurrentPage = <QuizWizard 
    resetQuiz={resetQuiz}
    setResetQuiz={setResetQuiz}
  />;

  if (pageType === "About") {
    CurrentPage = isMobile ? <AboutPageMobile /> : <AboutPage />;
  } else if (pageType === "Mentions") {
    CurrentPage = isMobile ? <MentionsPage /> : <MentionsPage />;
  } else if (pageType === "Explore") {
    CurrentPage = isMobile ? <ExplorePageMobile goToSectionIndex={goToSectionIndex} /> 
    : <ExplorePage goToSectionIndex={goToSectionIndex} />;
  } else if (pageType === "StaticResults") {
    CurrentPage = <Results
        imageSrcOne={imageSrcOne}
        imageSrcTwo={imageSrcTwo}
        isStaticResults
        hidePriceFilter={hidePriceFilter}
        hideNSFWToggle={hideNSFWToggle}
        metaDescription={metaDescription}
        title={title} 
        showNsfw={showNsfw || localStorage.getItem('showNsfwGlobal') === 'true'} 
      />;
  } else if (pageType === "Results") {
    CurrentPage = <Results showNsfw={localStorage.getItem('showNsfwGlobal') === 'true'} />;
  } else if (pageType === "Wishlists") {
    CurrentPage = <Wishlists wishlistId={wishlistId} />
  }

  return (
    <AuthProvider>
      <HeaderBar CurrentPage={CurrentPage} setResetQuiz={setResetQuiz} />
      <main className='main-content'>
        {CurrentPage}
      </main>
    </AuthProvider>
  );
}

export default Wizard;
