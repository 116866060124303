import React from 'react';
import classNames from 'classnames';
import { XMarkSvg } from '../../utils/functions';
import './styles.scss';

const STYLE_BASE = "MODAL-";
const WRAPPER_CLASSNAME = `${STYLE_BASE}wrapper`;

/**
 * 
 * @param {React.ReactNode} children
 * @param {Function} onModalClose callback executes when modal closes
 * @param {Boolean} showModal 
 * @returns 
 */
const Modal = ({children, containerClassName, onModalClose, showModal}) => {

  if(!showModal) {
    return null;
  }

  const isMobile = localStorage.getItem('isMobile') === 'true';
  const closeModalLength = isMobile ? 20 : 30;

  const closeModal = () => {
    onModalClose?.();
  }

  const handleWrapperOnClick = (event) => {
    if (event.target.className === WRAPPER_CLASSNAME) {
      closeModal();
    }
  }

  const handleCloseButtonOnClick = () => {
    closeModal();
  }
  return (
    <div className={WRAPPER_CLASSNAME} onClick={handleWrapperOnClick}>
      <div className={classNames(`${STYLE_BASE}container`, containerClassName)}>
        <div className={classNames(`${STYLE_BASE}closeButtonContainer`)}>
          <XMarkSvg 
            alt="Close Modal X icon"
            className={`${STYLE_BASE}closeModalIcon`}
            onClick={handleCloseButtonOnClick}
            height={closeModalLength}
            width={closeModalLength}
          />
        </div>
        {children}
      </div>
    </div>
  );
}

export default Modal;
