import React, { Component, createContext, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants.js';

export const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;

const ACCOUNT_API_URL = API_URL + "/api/user/";

const defaultState = {
    accessToken: localStorage.getItem("accessToken"),
    authActionFailed: false,
    authActionMessage: '',
    authActionPending: false,
    likedGiftIds: [],
    likedGiftlistId: null,
    wishlists: [],
};

export default class AuthProvider extends Component {
  state = defaultState;

  callFailed = (failureMessage) => {
    this.setState({
      authActionFailed: true,
      authActionMessage: failureMessage,
      authActionPending: false,
    });
  };

  callStart = () => {
    this.setState({
        authActionMessage: '',
        authActionPending: true,
    });
  };

  callSuccess = () => {
    this.setState({
        authActionFailed: false,
        authActionPending: false,
    });
  };

  clearAuthActionState = () => {
    this.setState({
        authActionFailed: false,
        authActionMessage: '',
        authActionPending: false,
    });
  };

  arrayRemove = (arr, value) => { 
    return arr.filter((ele) => { 
        return ele !== value; 
    });
  }

  addGiftToWishlist = async (wishlistId, giftId, onSuccess) => {
    const {
      accessToken,
    } = this.state;

    this.callStart();

    try {
      const response = await axios.post(`${ACCOUNT_API_URL}addGiftToWishlist?wishlistId=${wishlistId}&status=ACTIVE&giftId=${giftId}`, null, {
        headers: {
          'Authorization': `Token ${accessToken}`
        }
      });
      const success = response?.status === 200;

      if (success) {
        onSuccess && onSuccess();
        this.callSuccess();
        this.getLikedGiftIds();
      } else {
        this.callFailed('');
        return false;
      }
    } catch (e) {
      this.callFailed('');
      return false;
    }
    return true;
  };

  getWishlistGifts = async (wishlistId, onSuccess) => {
    const {
      accessToken,
    } = this.state;

    this.callStart();

    try {
      const response = await axios.get(`${ACCOUNT_API_URL}getWishlistGifts?wishlistId=${wishlistId}`, {
        headers: {
          'Authorization': `Token ${accessToken}`
        }
      });
      const success = response?.status === 200;

      if (success) {
        const data = response?.data;
        onSuccess && onSuccess(data);
        this.callSuccess();
        return true;
      } else {
        this.callFailed('');
        return false;
      }
    } catch (e) {
      this.callFailed('');
      return false;
    }
  };

  getLikedGiftIds = async () => {
    const {
      accessToken,
    } = this.state;

    this.callStart();

    try {
      const response = await axios.get(`${ACCOUNT_API_URL}getListOfLikedGiftIds`, {
        headers: {
          'Authorization': `Token ${accessToken}`
        }
      });
      const success = response?.status === 200;

      if (success) {
        const data = response?.data;
        this.setState({
          likedGiftlistId: data.likedGiftlistId,
          likedGiftIds: data.giftIds,
        });
        this.callSuccess();
        return true;
      } else {
        this.callFailed('');
        return false;
      }
    } catch (e) {
      this.callFailed('');
      return false;
    }
  };

  getWishlists = async () => {
    const {
      accessToken,
    } = this.state;
    this.callStart();

    try {
      const response = await axios.get(`${ACCOUNT_API_URL}getWishlists`, {
        headers: {
          'Authorization': `Token ${accessToken}`
        }
      });
      const success = response?.status === 200;

      if (success) {
        const data = response?.data;
        this.setState({"wishlists": data});
        this.callSuccess();
      } else {
        this.callFailed('');
        return false;
      }
    } catch (e) {
      this.callFailed('');
      return false;
    }
    return true;
  };
    
  login = async (username, password) => {
    this.callStart();

    try {
      const response = await axios.post(
        `${ACCOUNT_API_URL}login`,
        {
            username,
            password
        }
      );
      const success = response?.status === 200;

      if (success) {
          const accessToken = response?.data?.token;

          localStorage.setItem("username", username);
          localStorage.setItem("accessToken", accessToken);
          this.setState({"accessToken": accessToken});
          this.callSuccess();
      } else {
          this.callFailed('Failed to login');
          return false;
      }
    } catch (e) {
      this.callFailed('Failed to login');
      return false;
    }
    return true;
  };

  removeGiftFromWishlist = async (giftId) => {
    const {
      accessToken,
      likedGiftlistId,
    } = this.state;
    this.callStart();

    try {
      const response = await axios.post(`${ACCOUNT_API_URL}updateWishlistGift?wishlistId=${likedGiftlistId}&status=${"UNLIKED_GIFT"}&giftId=${giftId}`, null, {
        headers: {
          'Authorization': `Token ${accessToken}`
        }
      });
      const success = response?.status === 200;

      if (success) {
        this.callSuccess();
        this.getLikedGiftIds();
      } else {
        this.callFailed('');
        return false;
      }
    } catch (e) {
      this.callFailed('');
      return false;
    }
    return true;
  }

  logout = () => {
    this.setState({
      ...defaultState,
      accessToken: null,
    })
  }

  signup = async (signupValues) => {
    this.callStart();

    try {
      const response = await axios.post(
        `${ACCOUNT_API_URL}create`,
        {
            ...signupValues,
            "username": signupValues.email
        }
    );
      const success = response?.status === 200;

      if (success) {
        const accessToken = response?.data?.token;
        const username = response?.data?.username;

        localStorage.setItem("username", username);
        localStorage.setItem("accessToken", accessToken);
        this.setState({"accessToken": accessToken});
        this.callSuccess();
      } else {
        this.callFailed('');
        return "Failed to sign up. Please double check your request";
      }
    } catch (e) {
      this.callFailed('');
      const response = e?.response;
      if (response?.message) {
        return response.message;
      } else {
          return "Failed to sign up. Please double check your request";
      }
    }
    return '';
  };

  updateWishlistGift = async (giftId, wishlistId, status, onSuccess) => {
    const {
      accessToken,
    } = this.state;
    this.callStart();

    try {
      const response = await axios.post(`${ACCOUNT_API_URL}updateWishlistGift?wishlistId=${wishlistId}&status=${status}&giftId=${giftId}`, null, {
        headers: {
          'Authorization': `Token ${accessToken}`
        }
      });
      const success = response?.status === 200;

      if (success) {
        onSuccess && onSuccess();
        this.callSuccess();
      } else {
        this.callFailed('');
        return false;
      }
    } catch (e) {
      this.callFailed('');
      return false;
    }
    return true;
  };

  wishlistCreate = async (quizResultsUrl, wishlistName) => {
    const {
      accessToken,
    } = this.state;
    this.callStart();

    const requestData = {
      quizResultsUrl,
      "name": wishlistName,
    };

    try {
      const response = await axios.post(`${ACCOUNT_API_URL}wishlistCreate`, requestData, {
        headers: {
          'Authorization': `Token ${accessToken}`
        }
      });
      const success = response?.status === 200;
      const wishlistId = response?.data?.wishlistId;

      if (success) {
        this.callSuccess();
        this.getWishlists();
        return wishlistId;
      } else {
        this.callFailed('');
        return false;
      }
    } catch (e) {
      this.callFailed('');
      return false;
    }
  };

  render() {
    const {
        accessToken,
        authActionFailed,
        authActionMessage,
        authActionPending,
        wishlists,
    } = this.state;

    return (
      <AuthContext.Provider
        value={{
          accessToken,
          addGiftToWishlist: this.addGiftToWishlist,
          getWishlistGifts: this.getWishlistGifts,
          getWishlists: this.getWishlists,
          login: this.login,
          logout: this.logout,
          signup: this.signup,
          updateWishlistGift: this.updateWishlistGift,
          wishlistCreate: this.wishlistCreate,
          authActionFailed,
          authActionMessage,
          authActionPending,
          getLikedGiftIds: this.getLikedGiftIds,
          likedGiftlistId: this.state.likedGiftlistId,
          likedGiftIds: this.state.likedGiftIds,
          removeGiftFromWishlist: this.removeGiftFromWishlist,
          wishlists,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
