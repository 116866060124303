import React from 'react';
import classNames from 'classnames';
import StyledText from '../styled-text/component.js';
import './styles.scss';

const StyledButton = ({
  buttonClassName,
  buttonTextClassName,
  text,
  parentFunction,
  value,
  propButtonStyles,
  small = false,
  amazonVariant = false,
  lovabilityVariant = false,
  otherSellerVariant = false,
  grayVariant = false,
  giftButton = false,
}) => {
  const buttonClassNames = classNames(
    'styledButton',
    {
      smallButton: small,
      grayVariant,
      amazonVariant,
      lovabilityVariant,
      otherSellerVariant,
      giftButton: giftButton,
    },
    buttonClassName,
  )

  const styledTextClassNames = classNames(
    'styledButtonText',
    buttonTextClassName
  )

  return (
    <button
      className={buttonClassNames}
      style={propButtonStyles}
      onClick={() => parentFunction(value)}
    >
      <StyledText
        text={text || ''}
        textClassName={styledTextClassNames}
      />
    </button>
  );
}

export default StyledButton;
