export const IS_LOCAL = window.location.hostname === "localhost";
export const API_URL = IS_LOCAL ? 'http://127.0.0.1:8000' : 'https://evening-anchorage-89494.herokuapp.com';
// export const API_URL = 'https://evening-anchorage-89494.herokuapp.com';

export const RESULTS_ADVENTURE_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsAdventureFemaleResized.png';
export const RESULTS_ADVENTURE_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsAdventureMaleResized.png';
export const RESULTS_FUN_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsFunFemaleResized.png';
export const RESULTS_FUN_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsFunMaleResized.png';
export const RESULTS_SPORTS_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsSportsFemaleResized.png';
export const RESULTS_SPORTS_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsSportsMaleResized.png';
export const RESULTS_SEXY_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsSexyFemaleResized.png';
export const RESULTS_SEXY_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsSexyMaleResized.png';
export const RESULTS_ROMANTIC_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsRomanticFemaleResized.png';
export const RESULTS_ROMANTIC_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsRomanticMaleResized.png';
export const RESULTS_RELAX_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsRelaxFemaleResized.png';
export const RESULTS_RELAX_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsRelaxMaleResized.png';
export const RESULTS_PRACTICAL_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsPracticalFemaleResized.png';
export const RESULTS_PRACTICAL_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsPracticalMaleResized.png';
export const RESULTS_HIPPY_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsHippyFemaleResized.png';
export const RESULTS_HIPPY_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsHippieMaleResized.png';
export const RESULTS_PARTY_FEMALE_ALT = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsPartyFemaleAltResized.png';
export const RESULTS_PARTY_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsPartyFemaleResized.png';
export const RESULTS_PARTY_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsPartyMaleResized.png';
export const RESULTS_NERDY_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsNerdyFemaleResized.png';
export const RESULTS_NERDY_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsNerdMaleResized.png';
export const RESULTS_GIRL = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsGirlResized.png';
export const RESULTS_BOY = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsBoyResized.png';
export const RESULTS_HANDY_FEMALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsHandyFemaleResized.png';
export const RESULTS_HANDY_MALE = 'https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsHandyMaleResized.png';;
export const RESULTS_AESTHETIC_FEMALE = "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsAestheticFemaleResized.png";
export const RESULTS_AESTHETIC_MALE = "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsAestheticMaleResized.png";
export const RESULTS_SENTIMENTAL_FEMALE = "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsSentimentalFemaleResized.png";
export const RESULTS_SENTIMENTAL_MALE = "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/resultsImages/resultsSentimentalMaleResized.png";;

export const WEBSITE_EMAIL = "wtfdotheywant@gmail.com";
export const WEBSITE_URL = "https://www.wtfdotheywant.com/";

export const TYPES_OF_QUIZZES = [
    'friendquiz',
    'familyquiz',
    'selfquiz',
    'romanticquiz',
    'coworkerquiz',
    'kidsquiz',
];

export const PRIMARY_COLOR_CODE = "#FF1654";
export const SECONDARY_COLOR_CODE = "#FF8A5F";
export const TRACK_BACKGROUND_COLOR_CODE = "#e9e9e9";