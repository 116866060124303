import React, { lazy, useState, useEffect } from 'react';
import Modal from '../../../components/modal/component.js';
import StyledText from '../../../components/styled-text/component.js';
import StyledButton from 'components/styled-button/component.js';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/authContext.js';
import { ModalState } from './constants.js';
import './styles.scss';

const LoginModal = lazy(() => import('./login-modal/component.js'));
const SignupModal = lazy(() => import('./signup-modal/component.js'));

export const ProfileIcon = () => {
  return (
    <svg className="svg-icon" viewBox="0 0 20 20">
      <path fill="none" d="M14.023,12.154c1.514-1.192,2.488-3.038,2.488-5.114c0-3.597-2.914-6.512-6.512-6.512
        c-3.597,0-6.512,2.916-6.512,6.512c0,2.076,0.975,3.922,2.489,5.114c-2.714,1.385-4.625,4.117-4.836,7.318h1.186
        c0.229-2.998,2.177-5.512,4.86-6.566c0.853,0.41,1.804,0.646,2.813,0.646c1.01,0,1.961-0.236,2.812-0.646
        c2.684,1.055,4.633,3.568,4.859,6.566h1.188C18.648,16.271,16.736,13.539,14.023,12.154z M10,12.367
        c-2.943,0-5.328-2.385-5.328-5.327c0-2.943,2.385-5.328,5.328-5.328c2.943,0,5.328,2.385,5.328,5.328
        C15.328,9.982,12.943,12.367,10,12.367z"></path>
    </svg>
  )
}

const handleLogout = () => {
  localStorage.setItem("username", "");
  localStorage.setItem("accessToken", "");
}

const ProfileHeaderMenu = ({ buttonText, loginButtonClassname, startLoggingIn, modalContainerClassname }) => {
  const {
    accessToken,
    logout,
  } = useAuthContext();

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [modalState, setModalState] = useState(startLoggingIn ? ModalState.SIGN_IN : ModalState.SIGN_UP);
  const location = useLocation();

  useEffect(() => {
    setModalState(ModalState.SIGN_UP);
  }, [location]);
  
  const closeModal = () => {
    setShowAuthModal(false);
    setModalState(ModalState.SIGN_UP);
  }

  const ChildModal = modalState === ModalState.SIGN_IN ? 
    <LoginModal changeModalState={setModalState} closeModal={closeModal} /> :
    <SignupModal changeModalState={setModalState} closeModal={closeModal}/>;

  const username = localStorage.getItem("username") || '';
  const isSignedIn = !!accessToken && !!username;
  const usernameCleaned = username ? username.split("@")[0] : '';

  return (
    <div className="profileHeaderMenuContainer">
      {
        isSignedIn ?
        <div className="usernameAndLogoutContainer">
          <div className="username">
            <ProfileIcon />
            <StyledText
              text={usernameCleaned}
              textClassName="profileHeaderText"
            />
          </div>
          <StyledButton 
            parentFunction={() => {
              handleLogout();
              logout();
            }}
            text="LOG OUT"
            small
          />
        </div>
        :
        <StyledButton
          buttonClassName={loginButtonClassname}
          parentFunction={() => {
            setShowAuthModal(true);
          }}
          text={buttonText || "LOG IN | SIGN UP"}
          small
        />
      }
      <Modal 
        children={ChildModal} 
        onModalClose={() => closeModal()}
        containerClassName={modalContainerClassname}
        showModal={showAuthModal} 
      />
    </div>
  );
}

export const LoginLogoutModal = ({modalContainerClassname, showingAuthModal}) => {
  const [showAuthModal, setShowAuthModal] = useState(showingAuthModal);
  const [modalState, setModalState] = useState(ModalState.SIGN_UP);
  const location = useLocation();

  const closeModal = () => {
    setShowAuthModal(false);
    setModalState(ModalState.SIGN_UP);
  }

  const ChildModal = modalState === ModalState.SIGN_IN ? 
    <LoginModal changeModalState={setModalState} closeModal={closeModal} /> :
    <SignupModal changeModalState={setModalState} closeModal={closeModal}/>;

  useEffect(() => {
    setModalState(ModalState.SIGN_UP);
  }, [location]);

  useEffect(() => {
    setShowAuthModal(showingAuthModal);
  }, [showingAuthModal]);

  return (
    <div className="noShowLoginContainer">
      <Modal 
        children={ChildModal} 
        onModalClose={() => closeModal()}
        containerClassName={modalContainerClassname}
        showModal={showAuthModal} 
      />
    </div>
  );
}

export default ProfileHeaderMenu;
