import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import StyledTitle from '../../../components/styled-title/component.js';
import { convertToWebp } from '../../../utils/functions.js';
import { giftImageIconSrc } from './constants.js';
import './styles.scss';

const TitleWithIcon = ({
  containerClassName = "", 
  setResetQuiz,
}) => {
  const [titleIsHovered, setTitleIsHovered] = React.useState(false);

  const isMobile = localStorage.getItem('isMobile') === 'true';
  const isDesktop = localStorage.getItem('isDesktop') === 'true';
  const giftIconLength = isDesktop ? "100" : "70";

  return (
    <Fragment>
      {isMobile ?
        <div className="titleWithIconContainerMobile">
          <Link 
            className={containerClassName}
            onClick={() => setResetQuiz(true)}
            to='/'
          >
            <picture>
              <source srcSet={convertToWebp(giftImageIconSrc)} type="image/webp" />
              <source srcSet={giftImageIconSrc} type="image/jpeg" /> 
              <img
                alt="Gift Icon"
                className="homepageGiftIcon"
                height={'50'}
                width={'50'}
              />
            </picture>
          </Link>
          <StyledTitle
            onClick={() => setResetQuiz(true)}
            url="/"
            text="WTF DO THEY WANT"
            titleClassName="titleWithIconTextStyle"
          />
        </div> 
      :
        <div className="titleWithIconContainer">
          <Link 
            className={containerClassName}
            onClick={() => setResetQuiz(true)}
            onMouseEnter={() => setTitleIsHovered(true)}
            onMouseLeave={() => setTitleIsHovered(false)}
            to='/'
          >
            <picture>
              <source srcSet={convertToWebp(giftImageIconSrc)} type="image/webp" />
              <source srcSet={giftImageIconSrc} type="image/jpeg" /> 
              <img
                alt="Gift Icon"
                className="homepageGiftIcon"
                height={giftIconLength}
                width={giftIconLength}
              />
            </picture>
          </Link>
          <StyledTitle
            onClick={() => setResetQuiz(true)}
            onMouseEnter={() => setTitleIsHovered(true)}
            onMouseLeave={() => setTitleIsHovered(false)}
            url="/"
            text="WTF DO THEY WANT"
            titleClassName={`titleWithIconTextStyle ${titleIsHovered ? 'titleWithIconTextHoveredStyle': ''}`}
          />
        </div>
      }
    </Fragment>
  );
}

export default TitleWithIcon;