import React, { useEffect, useMemo } from 'react';
import StyledTitle from '../../../components/styled-title/component';
import Dropdown from '../../../components/dropdown/component';
import { useAuthContext } from '../../../contexts/authContext';

const NOT_LOGGED_IN_TEXT = "Login to create and customize gift lists!";

const WishlistsDropdown = () => {
  const {
    accessToken,
    getWishlists,
    wishlists,
  } = useAuthContext();

  useEffect(() => {
    if (accessToken) {
      getWishlists();
    }
  }, [accessToken, getWishlists]);

  const hasWishlists = wishlists && wishlists.length > 0;
  
  const wishlistMenuItems = useMemo(() => {
    if (!hasWishlists) {
      return [];
    }
    return wishlists.map((item) => ({
      title: item.name,
      path: `/wishlists/${item.id}`
    }));
  }, [wishlists, hasWishlists]);

  return (
    <Dropdown
      title="YOUR GIFTLISTS"
      path="/wishlists"
      contentWidth="100%"
      truncated
      menuItems={wishlistMenuItems}
    >
      {(!hasWishlists && !accessToken) &&
        <div>
          <StyledTitle
            text={NOT_LOGGED_IN_TEXT}
          />
        </div>
      }
    </Dropdown>
  );
}

export default WishlistsDropdown;