import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const DynamicHeader = ({ level, text }) => {
  if (!level) {
    return ( 
      <p style={{display:"contents"}}>
        {text}
      </p>
    );
  }

  const HeaderElement = `h${level}`;

  return React.createElement(HeaderElement, null, text);
};

const StyledTitle = ({
  containerClassName = '',
  titleClassName = '',
  onClick,
  onMouseEnter,
  onMouseLeave,
  title,
  subMenu,
  profileTitle,
  text,
  url = '',
  headerNumber = 2
}) => {

  const noLink = !url;
  let titleTypeClassName = 'linkTitleStyle';
  if (subMenu) {
    titleTypeClassName = "subMenuTitleStyle";
  } else if (noLink && !subMenu && !onClick) {
    titleTypeClassName = "noLinkTitleStyle";
  }

  if (profileTitle) {
    titleTypeClassName = titleTypeClassName + " profileHeaderTitle";
  }
  if (titleClassName) {
    titleTypeClassName = titleTypeClassName + " " + titleClassName;
  }

  return (
    <div 
      className={`titleContainer ${containerClassName}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!noLink && (
        <Link
          className={`styledTitle ${titleTypeClassName}`}
          onClick={onClick}
          to={url}
          target={""}
          title={title}
        >
          {text}
        </Link>
      )}
      {noLink && (
        <div
          className={`styledTitle ${titleTypeClassName}`}
          onClick={onClick}
          title={title}
        >
          <DynamicHeader level={headerNumber} text={text} />
        </div>
      )}
    </div>
  );
}

export default StyledTitle;
