import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  render() {
    if (this.state.hasError) {
      return <Navigate push to={`/error`} />
    }
    return this.props.children;
  }
}

export default ErrorBoundary;