import React, { lazy } from 'react';
import TitleWithIcon from './title-with-icon/component.js';
import ProfileHeaderMenu from './profile-header-menu/component.js';
import WishlistsDropdown from './wishlists-dropdown/component.js';
import './styles.scss';

const HamburgerMenu = lazy(() => import('./hamburger-menu/component.js'));
const ExtraPagesDisplay = lazy(() => import('./left-header-menu/component.js'));

const HeaderBar = ({CurrentPage, setResetQuiz}) => {
  const isMobile = localStorage.getItem('isMobile') === 'true';

  if (isMobile) {
    return (
      <nav className="headerBarContainer">
        <TitleWithIcon
          containerClassName="mobileGiftIconContainer"
          setResetQuiz={setResetQuiz}
        />
        <HamburgerMenu CurrentPage={CurrentPage} />
      </nav>
    );
  } else {
    return (
      <nav className="headerBarContainer">
        <TitleWithIcon setResetQuiz={setResetQuiz} />
        <ExtraPagesDisplay />
        <div className="wishlistAndProfileHeaderContainer"> 
          <WishlistsDropdown />
          <ProfileHeaderMenu />
        </div>
      </nav>
    );
  }
}

export default HeaderBar;
