import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, Navigate, BrowserRouter as Router } from 'react-router-dom';
import App from 'containers/app/component.js';
import { createBrowserHistory } from "history";
import * as constants from 'utils/constants.js';
import 'index.scss';

export default createBrowserHistory(); 

const history = createBrowserHistory();

const date = new Date(Date.now());
const currentYear = date.getFullYear();

const ErrorPage = lazy(() => import('containers/pages/error-page/component.js'));

// metaTitle - 50-60 chars
// metaDescription - 150-160 chars

// staticResultsPages - PROPS:
// hideNSFWToggle
// hidePriceFilter
// showNsfw
// imageSrcOne
// imageSrcTwo
// metaTitle
// metaDescription
// title
// path
const staticResultsPages = [
	{
		imageSrcOne: constants.RESULTS_FUN_FEMALE,
		imageSrcTwo: constants.RESULTS_SPORTS_MALE,
		metaTitle:"All the Gifts for Anyone & Any Occasion | WtfDoTheyWant",
		metaDescription:"Explore the ultimate collection of carefully curated gifts. Every gift idea this site has to offer all in one place, for people of all interests, ages and occasions",
		title:"ALL GIFTS",
		path: '/allgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HIPPY_MALE,
		imageSrcTwo: constants.RESULTS_PARTY_FEMALE_ALT,
		metaTitle:`The Most Popular Gift Ideas of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"The creamiest gifts of the gift crop. Among all the amazing gift ideas we have found running this site, these are the most popular and beloved gifts",
		title:"POPULAR GIFTS",
		path: '/populargifts', 
	},
	{
		imageSrcOne: constants.RESULTS_PRACTICAL_MALE,
		imageSrcTwo: constants.RESULTS_PARTY_FEMALE,
		metaTitle:`The Most Affordable Gift Ideas in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"The affordable gifts that fit into your budget. A variety of wonderful cheap gift ideas, all while remaining under 20 dollars",
		title:"AFFORDABLE GIFTS",
		path: '/affordablegifts',
		hidePriceFilter: true,
	},
	{
		imageSrcOne: constants.RESULTS_FUN_MALE,
		imageSrcTwo: constants.RESULTS_FUN_FEMALE,
		metaTitle:`The Most Joyful & Fun Gift Ideas of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"The funnest gifts for the joyful souls in our lives. This list encompasses all gifts that bring childlike joy to the recipient",
		title:"FUN GIFTS",
		path: '/fungifts',
	},
	{
		imageSrcOne: constants.RESULTS_RELAX_FEMALE,
		imageSrcTwo: constants.RESULTS_RELAX_MALE,
		metaTitle:`The Most Relaxing Gift Ideas of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Discover serenity in our collection of relaxing gifts. From soothing spa essentials to calming accessories, find the perfect present for moments of tranquility",
		title:"RELAXING GIFTS",
		path: '/relaxinggifts',
	},
	{
		imageSrcOne: constants.RESULTS_PRACTICAL_MALE,
		imageSrcTwo: constants.RESULTS_PRACTICAL_FEMALE,
		metaTitle:`The Most Practical & Useful Gifts of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Explore the realm of practical gifts designed to enhance daily life. From ingenious gadgets to versatile tools, find the perfect blend of utility and thoughtfulness",
		title:"PRACTICAL GIFTS",
		path: '/practicalgifts',
	},
	{
		imageSrcOne: constants.RESULTS_NERDY_MALE,
		imageSrcTwo: constants.RESULTS_NERDY_FEMALE,
		metaTitle:"A List of the Best Nerdy & Dorky Gift Ideas | WtfDoTheyWant",
		metaDescription:"Unleash the geeky joy with our curated selection of nerdy gifts. From tech gadgets to pop culture treasures, find the ideal presents for the proud nerd in your life",
		title:"NERDY GIFTS",
		path: '/nerdygifts',
	},
	{
		imageSrcOne: constants.RESULTS_PARTY_MALE,
		imageSrcTwo: constants.RESULTS_PARTY_FEMALE,
		metaTitle:"The Best Social Gift Ideas to Liven a Party | WtfDoTheyWant",
		metaDescription:"Elevate social connections with our unique range of gifts. From party essentials to conversation starters, discover thoughtful presents that enhance shared moments and strengthen bonds",
		title:"SOCIAL GIFTS",
		path: '/socialgifts',
	},
	{
		imageSrcOne: constants.RESULTS_SPORTS_MALE,
		imageSrcTwo: constants.RESULTS_SPORTS_FEMALE,
		metaTitle:"Gift Ideas for the Athletic & Outdoorsy | WtfDoTheyWant",
		metaDescription:"Fuel the spirit of adventure with our selection of athletic and outdoorsy gifts. Find the perfect presents for the thrill-seekers and fitness enthusiasts in your life",
		title:"ATHLETIC GIFTS",
		path: '/sportsgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HIPPY_FEMALE,
		imageSrcTwo: constants.RESULTS_HIPPY_MALE,
		metaTitle:"The Best Spiritual and Religious Gift Ideas | WtfDoTheyWant",
		metaDescription:"The perfect gifts for those in touch with their spiritual side. A list of the gifts that carry meaning to those who hold faith in something beyond the material",
		title:"SPIRITUAL GIFTS",
		path: '/spiritualgifts',
	},
	{
		imageSrcOne: constants.RESULTS_ROMANTIC_MALE,
		imageSrcTwo: constants.RESULTS_ROMANTIC_FEMALE,
		metaTitle:"The Most Romantic Gift Ideas For Your Love | WtfDoTheyWant",
		metaDescription:"Ignite passion with our enchanting collection of romantic gifts. Discover thoughtful presents that capture the essence of romance and deepen connections",
		title:"ROMANTIC GIFTS",
		path: '/romanticgifts',
	},
	{
		imageSrcOne: constants.RESULTS_AESTHETIC_MALE,
		imageSrcTwo: constants.RESULTS_AESTHETIC_FEMALE,
		metaTitle:`The Most Beautiful Gift Ideas in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Aesthetically pleasing gifts for those with an appreciation for beauty. From stunning decor to stylish accessories, help beautify their world with our curated aesthetic gifts",
		title:"AESTHETIC GIFTS",
		path: '/aestheticgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HANDY_MALE,
		imageSrcTwo: constants.RESULTS_HANDY_FEMALE,
		metaTitle:`The Most Handy Gift Ideas in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Empower the handyman & handywoman in your life with our range of handy gifts. From innovative tools to multipurpose gadgets help them embrace their inner-builder",
		title:"HANDY GIFTS",
		path: '/handygifts',
	},
	{
		imageSrcOne: constants.RESULTS_SENTIMENTAL_MALE,
		imageSrcTwo: constants.RESULTS_SENTIMENTAL_FEMALE,
		metaTitle:`The Best Sentimental Gift Ideas in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Craft lasting memories with our sentimental gifts collection. From personalized keepsakes to heartfelt treasures, explore presents that celebrate special moments",
		title:"SENTIMENTAL GIFTS",
		path: '/sentimentalgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HANDY_MALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Gift Ideas For Him in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"The perfect gifts for the man in your life. Be it gifts for your boyfriend, husband or crush. This list provides the thoughtful gifts that he will adore",
		title:"GIFTS FOR HIM",
		path: '/forhimgifts',
	},
	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_RELAX_FEMALE,
		metaTitle:`The Best Gift Ideas For Her in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"The perfect gifts for the woman in your life. Be it gifts for your girlfriend, wife or crush. This list provides the thoughtful gifts that she will adore",
		title:"GIFTS FOR HER",
		path: '/forhergifts',
	},
	{
		imageSrcOne: constants.RESULTS_BOY,
		imageSrcTwo: constants.RESULTS_GIRL,
		hideNSFWToggle: true,
		metaTitle:`The Best Kids Gifts for All Ages in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Wonderful gifts for the children in your life. Gifts for infants, toddlers, preschoolers and middle childhood. Beloved gifts for kids of all ages and interests",
		title:"KIDS GIFTS",
		path: '/kidsgifts',
	},
	{
		imageSrcOne: constants.RESULTS_AESTHETIC_MALE,
		imageSrcTwo: constants.RESULTS_NERDY_FEMALE,
		metaTitle:`Latest & Greatest Gifts of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"The latest and greatest gifts. Stay on top of the trends with a list of all the newest gift ideas",
		title:"NEW GIFTS",
		path: '/newgifts',
	},
	{
		hideNSFWToggle: true,
		showNsfw: true,
		imageSrcOne: constants.RESULTS_SEXY_MALE,
		imageSrcTwo: constants.RESULTS_SEXY_FEMALE,
		metaTitle:`The Most Scandalous NSFW Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"The scandalous gift ideas that aren't appropriate for work. All the gifts that are a bit too spicy for the wrong eyes, but are fantastic for the right person",
		title:"NSFW GIFTS",
		path: '/nsfwgifts',
	},
	{
		hidePriceFilter: true,
		hideNSFWToggle: true,
		imageSrcOne: constants.RESULTS_PRACTICAL_MALE,
		imageSrcTwo: constants.RESULTS_PRACTICAL_FEMALE,
		metaTitle:`The Best Free / DIY Gift Ideas in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"The quality of a gift is not measured by the cost, but the thought behind the gesture. A great do-it-yourself gift will mean the world, without spending a dime",
		title:"FREE / DIY GIFTS",
		path: '/freegifts',
	},
	{
		hideNSFWToggle: true,
		imageSrcOne: constants.RESULTS_SENTIMENTAL_MALE,
		imageSrcTwo: constants.RESULTS_NERDY_FEMALE,
		metaTitle:`The Best Gifts for Pets in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Spoil your furry friends of all kinds with our delightful selection of gifts! From toys to cozy accessories, find the perfect present for your beloved companion",
		title:"GIFTS FOR PETS",
		path: '/petsgifts',
	},
	{
		imageSrcOne: constants.RESULTS_FUN_FEMALE,
		imageSrcTwo: constants.RESULTS_FUN_MALE,
		metaTitle:`The Funniest Gifts of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Unleash laughter with our hilarious gift collection. From silly pranks to witty novelties, discover presents that guarantee laughs to any occasion",
		title:"FUNNY GIFTS",
		path: '/funnygifts',
	},
	{
		imageSrcOne: constants.RESULTS_AESTHETIC_MALE,
		imageSrcTwo: constants.RESULTS_NERDY_FEMALE,
		metaTitle:`The Most Unique Gifts of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Explore a world of one-of-a-kind treasures. From quirky finds to artisanal creations, discover gifts that stand out, making any occasion truly memorable",
		title:"UNIQUE GIFTS",
		path: '/uniquegifts',
	},
	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Hunting Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Gear up for the wild with our selection of hunting gifts. From rugged equipment to essential accessories, find the perfect presents for the hunter in your life",
		title:"HUNTING GIFTS",
		path: '/huntinggifts',
	},
	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Fishing Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Reel in the perfect catch with our premier selection of fishing gifts. From high-quality tackle to innovative accessories for the fishing enthusiast in your life",
		title:"FISHING GIFTS",
		path: '/fishinggifts',
	},
	{
		imageSrcOne: constants.RESULTS_AESTHETIC_MALE,
		imageSrcTwo: constants.RESULTS_NERDY_FEMALE,
		metaTitle:`The Best Artistic Gift Ideas in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Immerse yourself in creativity with our curated artistic gifts. Explore the presents that ignite the artistic spirit and help build a canvas for expression",
		title:"ARTISTIC GIFTS",
		path: '/artisticgifts',
	},
	{
		imageSrcOne: constants.RESULTS_AESTHETIC_MALE,
		imageSrcTwo: constants.RESULTS_AESTHETIC_FEMALE,
		metaTitle:`The Most Fashionable Gifts of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Elevate style with our curated fashion gifts. From trendy accessories to timeless classics, discover elegance and make a lasting statement in every wardrobe",
		title:"FASHION GIFTS",
		path: '/fashiongifts',
	},
	{
		imageSrcOne: constants.RESULTS_SENTIMENTAL_FEMALE,
		imageSrcTwo: constants.RESULTS_SENTIMENTAL_MALE,
		metaTitle:`Best Gift Ideas for Retirement in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Celebrate a new chapter with a thoughtful retirement gift. From personalized mementos to leisurely essentials, find presents that honor a great life and inspire the relaxation ahead",
		title:"RETIREMENT GIFTS",
		path: '/retirementgifts',
	},
	{
		imageSrcOne: constants.RESULTS_PARTY_FEMALE_ALT,
		imageSrcTwo: constants.RESULTS_FUN_MALE,
		metaTitle:`The Best Birthday Gift Ideas of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Make this birthday unforgettable with our curated Birthday gift ideas. Discover presents that celebrate individuality and bring joy to every birthday celebration",
		title:"BIRTHDAY GIFTS",
		path: '/birthdaygifts',
	},
	{
		imageSrcOne: constants.RESULTS_PARTY_FEMALE_ALT,
		imageSrcTwo: constants.RESULTS_SENTIMENTAL_MALE,
		metaTitle:`The Best Christmas Gift Ideas of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Embrace the spirit of giving with our most festive Christmas gifts. Discover presents that bring warmth and joy to your loved ones during the most wonderful time of the year",
		title:"CHRISTMAS GIFTS",
		path: '/christmasgifts',
	},
	{
		imageSrcOne: constants.RESULTS_ROMANTIC_MALE,
		imageSrcTwo: constants.RESULTS_RELAX_FEMALE,
		metaTitle:`The Best Anniversary Gift Ideas of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Celebrate enduring love with our handpicked anniversary gifts. Cherish another year together with gift ideas that will help you commemorate the path you build together",
		title:"ANNIVERSARY GIFTS",
		path: '/anniversarygifts',
	},
	{
		imageSrcOne: constants.RESULTS_ROMANTIC_MALE,
		imageSrcTwo: constants.RESULTS_RELAX_FEMALE,
		metaTitle:`The Best Wedding Gifts of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Elevate the joy of their special day with our carefully curated wedding gifts. Find the perfect presents for the beginning of a beautiful journey together",
		title:"WEDDING GIFTS",
		path: '/weddinggifts',
	},
	{
		imageSrcOne: constants.RESULTS_FUN_MALE,
		imageSrcTwo: constants.RESULTS_PARTY_FEMALE_ALT,
		metaTitle:`The Best Housewarming Gifts of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Extend a warm welcome to new spaces with our thoughtfully chosen housewarming gifts. From stylish decor to practical essentials, explore presents that turn a house into a home",
		title:"HOUSEWARMING GIFTS",
		path: '/housewarminggifts',
	},
	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_FUN_MALE,
		metaTitle:`The Best Graduation Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Commemorate academic milestones with our curated graduation gifts. From inspiring keepsakes to practical tools, discover presents that celebrate achievement and inspire future success",
		title:"GRADUATION GIFTS",
		path: '/graduationgifts',
	},
	{
		imageSrcOne: constants.RESULTS_PRACTICAL_MALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Father's Day Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Honor Dad with our handpicked Father's Day gifts. Dads are notoriously hard to shop for. We narrowed down the gifts to show your father appreciation for all they have done",
		title:"FATHER'S DAY GIFTS",
		path: '/fathersdaygifts',
	},
	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_SENTIMENTAL_FEMALE,
		metaTitle:`The Best Mother's Day Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Show Mom your love with our curated Mother's Day gifts. From sentimental keepsakes to pampering essentials, express gratitude and celebrate the incredible woman she is",
		title:"MOTHER'S DAY GIFTS",
		path: '/mothersdaygifts',
	},
	{
		imageSrcOne: constants.RESULTS_SENTIMENTAL_FEMALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Bar & Bat Mitzvah Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Mark this special milestone with our unique Bar/Bat Mitzvah gifts. Find the presents that honor tradition and embrace the joy of coming of age",
		title:"BAR / BAT MITZVAH GIFTS",
		path: '/barmitzvahgifts',
		hideNSFWToggle: true,
	},
	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_PARTY_FEMALE_ALT,
		metaTitle:`The Best Quinceanera Gifts of ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Celebrate the transition to womanhood with our Quinceanera gifts. Discover presents that honor tradition and add a touch of glamour to this memorable rite of passage",
		title:"QUINCEANERA GIFTS",
		path: '/quinceaneragifts',
		hideNSFWToggle: true,
	},
	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_RELAX_FEMALE,
		metaTitle:`The Best Valentine's Day Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Celebrate love with our handpicked Valentine's Day gifts. From romantic gestures to heartfelt surprises, discover presents that ignite passion and create unforgettable moments",
		title:"VALENTINE'S DAY GIFTS",
		path: '/valentinesdaygifts',
	},
	{
		imageSrcOne: constants.RESULTS_SENTIMENTAL_FEMALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Hanukkah Gift Ideas in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Celebrate the festival of lights with our curated Hanukkah gifts. Find presents that honor tradition and bring joy to the celebration of light and hope",
		title:"HANUKKAH GIFTS",
		path: '/hanukkahgifts',
	},
	{
		imageSrcOne: constants.RESULTS_FUN_FEMALE,
		imageSrcTwo: constants.RESULTS_PARTY_FEMALE_ALT,
		metaTitle:`The Best White Elephant Gift Ideas in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Add laughter to the gift exchange with our whimsical White Elephant gifts. From quirky novelties to hilarious surprises, find presents that spark fun and create memorable moments",
		title:"WHITE ELEPHANT GIFTS",
		path: '/whiteelephantgifts',
	},
	{
		imageSrcOne: constants.RESULTS_PRACTICAL_MALE,
		imageSrcTwo: constants.RESULTS_PRACTICAL_FEMALE,
		metaTitle:`The Best Gift Ideas for Coworkers in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Strengthen office camaraderie with our thoughtful coworker gifts. From desk accessories to gourmet treats, find presents that show appreciation and make lasting workplace connections",
		title:"COWORKER GIFTS",
		path: '/coworkergifts',
	},
	{
		imageSrcOne: constants.RESULTS_BOY,
		imageSrcTwo: constants.RESULTS_GIRL,
		metaTitle:`The Best Stocking Stuffer Gifts in ${currentYear} | WtfDoTheyWant`,
		metaDescription:"Fill stockings with joy using our delightful stocking stuffer gifts. Discover presents that add a touch of holiday magic to every stocking hanging by the chimney with care",
		title:"STOCKING STUFFER GIFTS",
		path: '/stockingstuffergifts',
	},

	{
		imageSrcOne: constants.RESULTS_HANDY_FEMALE,
		imageSrcTwo: constants.RESULTS_RELAX_FEMALE,
		metaTitle:`The Best Gift Ideas for Mom | WtfDoTheyWant`,
		metaDescription:"Uncover the perfect blend of love and appreciation with our curated gifts for Mom. From sentimental keepsakes to indulgent treats, celebrate the queen of your heart.",
		title:"GIFTS FOR MOM",
		path: '/momgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HANDY_MALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Gift Ideas for Dad | WtfDoTheyWant`,
		metaDescription:"Elevate your dad's day with thoughtful gifts that match his unique style and interests. Explore a collection that captures the essence of your bond and makes every moment memorable.",
		title:"GIFTS FOR DAD",
		path: '/dadgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HIPPY_FEMALE,
		imageSrcTwo: constants.RESULTS_RELAX_FEMALE,
		metaTitle:`The Best Gift Ideas for Grandma | WtfDoTheyWant`,
		metaDescription:"Shower your grandma with warmth and affection through our charming gifts. From nostalgic treasures to cozy comforts, discover delightful surprises that express your love.",
		title:"GIFTS FOR GRANDMA",
		path: '/grandmagifts',
	},
	{
		imageSrcOne: constants.RESULTS_HIPPY_MALE,
		imageSrcTwo: constants.RESULTS_HANDY_MALE,
		metaTitle:`The Best Gift Ideas for Grandpa | WtfDoTheyWant`,
		metaDescription:"Honor your grandpa with gifts that reflect a lifetime of wisdom and joy. Choose from timeless classics to personalized treasures that celebrate the remarkable man he is.",
		title:"GIFTS FOR GRANDPA",
		path: '/grandpagifts',
	},
	{
		imageSrcOne: constants.RESULTS_FUN_MALE,
		imageSrcTwo: constants.RESULTS_SPORTS_MALE,
		metaTitle:`The Best Gift Ideas for Brothers | WtfDoTheyWant`,
		metaDescription:"Explore a world of gifts tailored for your brother's passions. From cool gadgets to stylish accessories, find the perfect way to show your appreciation for the guy who's always got your back.",
		title:"GIFTS FOR BROTHERS",
		path: '/brothergifts',
	},
	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_AESTHETIC_FEMALE,
		metaTitle:`The Best Gift Ideas for Sisters | WtfDoTheyWant`,
		metaDescription:"Celebrate the unique bond with your sister through our thoughtful gifts. From chic accessories to heartwarming keepsakes, discover treasures that speak to her individuality.",
		title:"GIFTS FOR SISTERS",
		path: '/sistergifts',
	},
	{
		imageSrcOne: constants.RESULTS_FUN_MALE,
		imageSrcTwo: constants.RESULTS_AESTHETIC_FEMALE,
		metaTitle:`The Best Gift Ideas for Cousins | WtfDoTheyWant`,
		metaDescription:"Surprise your cousin with gifts that reflect shared memories and inside jokes. From playful accessories to trendy finds, make their special moments even more unforgettable.",
		title:"GIFTS FOR COUSINS",
		path: '/cousingifts',
	},
	{
		imageSrcOne: constants.RESULTS_BOY,
		imageSrcTwo: constants.RESULTS_SPORTS_MALE,
		metaTitle:`The Best Gift Ideas for Sons | WtfDoTheyWant`,
		metaDescription:"Show your son how much he means to you with our handpicked gifts. From inspirational items to practical essentials, find the perfect way to express your pride and love.",
		title:"GIFTS FOR SONS",
		path: '/songifts',
	},
	{
		imageSrcOne: constants.RESULTS_PRACTICAL_FEMALE,
		imageSrcTwo: constants.RESULTS_GIRL,
		metaTitle:`The Best Gift Ideas for Daughters | WtfDoTheyWant`,
		metaDescription:"Explore our collection of gifts designed to celebrate your daughter's uniqueness. From elegant accessories to personalized gems, make her smile with a token of your enduring affection.",
		title:"GIFTS FOR DAUGHTERS",
		path: '/daughtergifts',
	},
	{
		imageSrcOne: constants.RESULTS_SPORTS_MALE,
		imageSrcTwo: constants.RESULTS_BOY,
		metaTitle:`The Best Gift Ideas for Grandsons | WtfDoTheyWant`,
		metaDescription:"Delight your grandson with gifts that capture the magic of his youth. From fun surprises to educational wonders, find treasures that create lasting memories together.",
		title:"GIFTS FOR GRANDSONS",
		path: '/grandsongifts',
	},
	{
		imageSrcOne: constants.RESULTS_PRACTICAL_FEMALE,
		imageSrcTwo: constants.RESULTS_GIRL,
		metaTitle:`The Best Gift Ideas for Granddaughters | WtfDoTheyWant`,
		metaDescription:"Cherish the special moments with your granddaughter through our enchanting gifts. From whimsical delights to meaningful keepsakes, make her world brighter and more magical.",
		title:"GIFTS FOR GRANDDAUGHTERS",
		path: '/granddaughtergifts',
	},
	{
		imageSrcOne: constants.RESULTS_SPORTS_FEMALE,
		imageSrcTwo: constants.RESULTS_GIRL,
		metaTitle:`The Best Gift Ideas for Nieces | WtfDoTheyWant`,
		metaDescription:"Express your love for your niece with our charming gifts. From trendy accessories to heartfelt mementos, find the perfect way to show her that she holds a special place in your heart.",
		title:"GIFTS FOR NIECES",
		path: '/niecegifts',
	},
	{
		imageSrcOne: constants.RESULTS_SPORTS_MALE,
		imageSrcTwo: constants.RESULTS_BOY,
		metaTitle:`The Best Gift Ideas for Nephews | WtfDoTheyWant`,
		metaDescription:"Celebrate your nephew's spirit with gifts that match his energy and interests. From cool gadgets to adventurous gear, discover treasures that reflect his unique personality.",
		title:"GIFTS FOR NEPHEWS",
		path: '/nephewgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HANDY_FEMALE,
		imageSrcTwo: constants.RESULTS_RELAX_FEMALE,
		metaTitle:`The Best Gift Ideas for Aunts | WtfDoTheyWant`,
		metaDescription:"Spoil your aunt with gifts that reflect her elegance and grace. From sophisticated indulgences to thoughtful tokens, discover a collection that expresses your admiration.",
		title:"GIFTS FOR AUNTS",
		path: '/auntgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HANDY_MALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Gift Ideas for Uncles | WtfDoTheyWant`,
		metaDescription:"Show your appreciation for your uncle with gifts that match his personality. From practical tools to stylish accessories, find treasures that celebrate the cool uncle in your life.",
		title:"GIFTS FOR UNCLES",
		path: '/unclegifts',
	},
	{
		imageSrcOne: constants.RESULTS_HANDY_FEMALE,
		imageSrcTwo: constants.RESULTS_RELAX_FEMALE,
		metaTitle:`The Best Gift Ideas for Your Mother In Law | WtfDoTheyWant`,
		metaDescription:"Impress your mother-in-law with gifts that radiate warmth and gratitude. From luxurious treats to personalized tokens, find the perfect way to express your love and appreciation.",
		title:"GIFTS FOR YOUR MOTHER IN LAW",
		path: '/motherinlawgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HANDY_MALE,
		imageSrcTwo: constants.RESULTS_ADVENTURE_MALE,
		metaTitle:`The Best Gift Ideas for Your Father In Law | WtfDoTheyWant`,
		metaDescription:"Honor your father-in-law with gifts that reflect his strength and wisdom. From classic essentials to outdoor adventures, discover treasures that resonate with his unique spirit.",
		title:"GIFTS FOR YOUR FATHER IN LAW",
		path: '/fatherinlawgifts',
	},

	{
		imageSrcOne: constants.RESULTS_ADVENTURE_FEMALE,
		imageSrcTwo: constants.RESULTS_FUN_MALE,
		metaTitle:`The Best Gift Ideas for Young Adults | WtfDoTheyWant`,
		metaDescription:"Unleash the excitement of giving with our curated gifts for young adults. Explore a collection that perfectly complements their vibrant lifestyle",
		title:"GIFTS FOR YOUNG ADULTS",
		path: '/youngadultgifts',
	},
	{
		imageSrcOne: constants.RESULTS_HIPPY_FEMALE,
		imageSrcTwo: constants.RESULTS_RELAX_MALE,
		metaTitle:`The Best Gift Ideas for Elderly Folks | WtfDoTheyWant`,
		metaDescription:"Celebrate the wisdom of age with our thoughtful gifts for elderly folks. From comfort-enhancing items to nostalgic treasures, find the perfect way to express love and admiration.",
		title:"GIFTS FOR ELDERLY FOLKS",
		path: '/elderlygifts',
	},
	{
		imageSrcOne: constants.RESULTS_HANDY_MALE,
		imageSrcTwo: constants.RESULTS_FUN_FEMALE,
		metaTitle:`Culinary Gifts for Folks Who Love to Cook | WtfDoTheyWant`,
		metaDescription:"Indulge the culinary connoisseur in your life with our exquisite culinary gifts. From gourmet delights to kitchen essentials, help elevate the joy of cooking.",
		title:"CULINARY GIFTS",
		path: '/culinarygifts',
	},
	{
		imageSrcOne: constants.RESULTS_GIRL,
		imageSrcTwo: constants.RESULTS_FUN_MALE,
		metaTitle:`The Best Gifts for Folks with a Sweet Tooth | WtfDoTheyWant`,
		metaDescription:"Satisfy their sweet tooth with our delightful array of sweet gifts. Explore a sugary world of surprises that bring out pure joy in a way that only sweets can.",
		title:"SWEET GIFTS",
		path: '/sweetgifts',
	},
	{
		imageSrcOne: constants.RESULTS_FUN_FEMALE,
		imageSrcTwo: constants.RESULTS_FUN_MALE,
		metaTitle:`The Best Prank Gift Ideas | WtfDoTheyWant`,
		metaDescription:"Add laughter and surprise to any occasion with our collection of prank gifts. From hilarious gags to clever tricks, create memorable moments filled with laughter.",
		title:"PRANK GIFTS",
		path: '/prankgifts',
	},
	{
		imageSrcOne: constants.RESULTS_PARTY_FEMALE,
		imageSrcTwo: constants.RESULTS_PARTY_MALE,
		metaTitle:`The Best Muscial Gift Ideas | WtfDoTheyWant`,
		metaDescription:"Strike the right chord with our musical gifts collection. Discover the perfect harmony of thoughtful and melodic surprises.",
		title:"MUSICAL GIFTS",
		path: '/musicgifts',
	},
];

// quizResultsPages - PROPS:
// metaTitle
// metaDescription
// path
const quizResultsPages = [
	{
		metaTitle:"Significant Other Gift Quiz Results | WtfDoTheyWant",
		metaDescription:"The quiz results for your intimate partner. A list of the gifts that match their personality and will show your loving affection",
		path: "/romanticquizResults",
	},
	{
		metaTitle:"Family Gift Quiz Results | WtfDoTheyWant",
		metaDescription:"The quiz results for your family member. A list of the gifts that match their personality and will show your care to the ones who matter most",
		path: "/familyquizResults",
	},
	{
		metaTitle:"Self Gift Quiz Results | WtfDoTheyWant",
		metaDescription:"The quiz results for yourself. Rewards yourself with the list of the gifts that match your personality and interests",
		path: "/selfquizResults",
	},
	{
		metaTitle:"Friend Gift Quiz Results | WtfDoTheyWant",
		metaDescription:"The quiz results for your close friend. A list of the gifts that match their personality and interests to show your care",
		path: "/friendquizResults",
	},
	{
		metaTitle:"Coworker Gift Quiz Results | WtfDoTheyWant",
		metaDescription:"The quiz results to decide the best gift for your coworker. A list of the gifts that match their personality and will make a great impression",
		path: "/coworkerquizResults",
	},
	{
		metaTitle:"Kids Gift Quiz Results | WtfDoTheyWant",
		metaDescription:"The quiz results for an important child in your life. Make lasting memories by getting a gift that matches their personality",
		path: "/kidsquizResults",
	},
];

// websitePageRoutes - PROPS:
// metaTitle
// metaDescription
// pageType
// path
// noIndex
// exact
// goToSectionIndex
const websitePageRoutes = [
	{
		metaTitle:"Explore Gift Lists Organized In Categories | WtfDoTheyWant",
		metaDescription:"Embark on a journey through our comprehensive gift idea catalog. Discover the gift section to match your needs; from occasions to personality traits to specific interests",
		pageType:"Explore",
		path: "/explore",
	},
	{
		metaTitle:"Explore Gift Ideas By Specialized Categories | WtfDoTheyWant",
		metaDescription:"Find the perfect gift using our categorized gift sections. Browse the curated selections of gifts organized into the categories that make gift shopping simple",
		pageType:"Explore",
		path: "/exploreByCategory",
		goToSectionIndex: 0,
	},
	{
		metaTitle:"Explore Gift Ideas By Occasion | WtfDoTheyWant",
		metaDescription:"Discover the perfect gift for any occasion. From birthdays to christmas to houswarming parties, find the presents to match the sentiment and importance of any celebration",
		pageType:"Explore",
		path: "/exploreByOccasion",
		goToSectionIndex: 1,
	},
	{
		metaTitle:"Explore Gift Lists By Personality Type | WtfDoTheyWant",
		metaDescription:"Discover personalized gift ideas expertly divided into unique personality types. Effortlessly choose the perfect gift that matches their character",
		pageType:"Explore",
		path: "/exploreByPersonality",
		goToSectionIndex: 2,
	},
	{
		metaTitle:"Explore Gift Lists By Interest Groups | WtfDoTheyWant",
		metaDescription:"Find the perfect gift based on their personal interests. Browse our curated selection of gifts organized by interest, making gift shopping a breeze",
		pageType:"Explore",
		path: "/exploreByInterest",
		goToSectionIndex: 3,
	},
	{
		metaTitle:"Explore Gift Lists By Relationship | WtfDoTheyWant",
		metaDescription:"Discover the art of thoughtful giving across your most important relationships. Explore our curated gift offerings tailored for every connection",
		pageType:"Explore",
		path: "/exploreByRelationship",
		goToSectionIndex: 4,
	},
	{
		metaTitle:"About the Site - History, Policies & Contact | WtfDoTheyWant",
		metaDescription:"A brief history of how WTFDoTheyWant.com came to be. The details on how data is stored, policies on affiliate sales and how to reach us",
		pageType:"About",
		path: "/about",
	},
	{
		metaTitle:"Website Mentions - WTF Spotted in the Wild | WtfDoTheyWant",
		metaDescription:"Discover the buzz around our website! Explore videos, links, and mentions from across the web that enjoyed the site",
		pageType:"Mentions",
		path: "/mentions",
	},
	{
		metaTitle:"User Created Personalized Gift Wishlists | WtfDoTheyWant",
		metaDescription:"The gift wishlists created for your loved ones. Personalize and record their quiz results; remove the gifts that aren't a good fit or those they already own",
		pageType:"Wishlists",
		path: "/wishlists",
		noIndex: true,
	},
	{
		metaTitle:"User Created Personalized Gift Wishlists | WtfDoTheyWant",
		metaDescription:"The gift wishlists created for your loved ones. Personalize and record their quiz results; remove the gifts that aren't a good fit or those they already own",
		pageType:"Wishlists",
		path: "/wishlists/:wishlistId",
		noIndex: true,
	},
	{
		exact: true,
		metaTitle:"Gift Finder - Quiz to find the Perfect Gift | WtfDoTheyWant",
		metaDescription:"The world's best gift finding website. Take a fun personality quiz to generate a list of personalized gift ideas. Discover the curated gifts fit just for them",
		pageType:"Quiz",
		path: "/",
	}
];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Router location={history}>
		<Routes>
			{staticResultsPages.map(route => {
				const StaticResultsComponent = 
				<App
					hidePriceFilter={route.hidePriceFilter}
					hideNSFWToggle={route.hideNSFWToggle}
					imageSrcOne={route.imageSrcOne}
					imageSrcTwo={route.imageSrcTwo}
					metaDescription={route.metaDescription}
					metaTitle={route.metaTitle}
					showNsfw={route.showNsfw}
					title={route.title}
					pageType="StaticResults"
				/>;
				return (
					<Route
						path={route.path} 
						element={StaticResultsComponent} 
						key={route.path}>
					</Route>
				);
			})}

			{quizResultsPages.map(route => {
				const QuizResultsComponent = 
				<App
					metaDescription={route.metaDescription}
					metaTitle={route.metaTitle}
					noIndex
					pageType="Results"
				/>;
				return (
					<Route
						path={route.path} 
						element={QuizResultsComponent} 
						key={route.path}>
					</Route>
				);
			})}

			{websitePageRoutes.map(route => {
				const WebsitePageComponent = 
				<App
					metaDescription={route.metaDescription}
					metaTitle={route.metaTitle}
					noIndex={route.noIndex}
					pageType={route.pageType}
					goToSectionIndex={route.goToSectionIndex}
				/>;
				return (
					<Route
						exact={route.exact} 
						path={route.path} 
						element={WebsitePageComponent} 
						key={route.path}>
					</Route>
				);
			})}
			<Route path="/error" element={<ErrorPage />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	</Router>
);

// handles clearing out the cache on new deployment
if ('serviceWorker' in navigator) {
	navigator.serviceWorker.ready.then(registration => {
		registration.unregister();

		if (caches) {
		// Service worker cache should be cleared with caches.delete()
		caches.keys().then(async (names) => {
			await Promise.all(names.map(name => caches.delete(name)));
		});
		}
	});
}

// hides console warnings in prod
function noop() {}
if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  //console.error = noop;
}