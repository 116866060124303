export const ModalState = {
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up'
}

export const ClassNames = {
  container: 'authModal-container',
  titleText: 'authModal-titleText',
  bodyText: 'authModal-bodyText',
  errorText: 'authModal-errorText',
  introContainer: 'authModal-introContainer',
  inputContainer: 'authModal-inputContainer',
  checkboxContainer: 'authModal-checkboxContainer'
}