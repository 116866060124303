import React from 'react';
import './styles.scss';

const DynamicHeader = ({ level, text }) => {
  if (!level) {
    return ( 
      <p style={{display:"contents"}}>
        {text}
      </p>
    );
  }

  const HeaderElement = `h${level}`;

  return React.createElement(HeaderElement, null, text);
};


const StyledText = ({
  bold,
  text,
  propStyles,
  textClassName = '',
  title,
  onClick,
  children,
  headerNumber = 0
}) => {

  return (
    <div
      className={`styledText ${textClassName} ${bold ? 'styledTextBold' : ''} ${onClick ? 'styledTextWithAction' : ''}`}
      style={propStyles}
      onClick={onClick}
      title={title}
    >
      <DynamicHeader level={headerNumber} text={text} />
      {children}
    </div>
  );
}

export default StyledText;
